import { isEmpty, isUndefined, each } from 'lodash'
import ko from '../locales/ko.json'
import axios from 'axios'
import * as yup from 'yup'
import uuid from '$$utils/uuid'
const AuthenticationGuard = {
  install: function (Vue, router, pinia) {
    Vue.$guardNext = Vue.config.globalProperties.$guardNext = function () {
      if (isEmpty(pinia.auth.befRoute) || !this.$pinia.auth.isAuth) {
        return
      }
      const name = pinia.auth.befRoute
      this.$pinia.auth.befRoute = ''
      this.$pinia.auth.isLogin = true
      router.push({ name: name, query: pinia.auth.befRouteQuery })
    }
    let commonCodeLoaded = false
    let yupDefauldLoaded = false
    router.beforeEach(async function (to, from, next) {
      Vue.$loading.hideAll()
      if (isEmpty(pinia.auth.startDate)) {
        await getStartDate()
        if (pinia.auth.analysis.key === '') {
          pinia.auth.analysis.key = uuid.short()
        }
      }
      if (to.name === 'CM0100M01') {
        if (from.name === 'CM0000M01' || from.path === '/') {
          console.log('root navigation')
        } else if (from.name === 'MB0200M01') {
          console.log('my page navigation')
        } else {
          pinia.auth.befRoute = from.name
          pinia.auth.befRouteQuery = from.query
        }
      }
      setAnalysisHistory(to.name)
      console.log('to, from', to, from)
      if (!isEmpty(pinia.auth.errcode)) next()
      await commonCode()
      const i18n = Vue.$i18n.global
      const comT = i18n.t
      if (to.name != 'CM0300M01' && to.name != 'CM0400M01') {
        await commonMessage(i18n)
        setReferId(to)
        await pinia.auth.CHECK_STATUS(to)
        // if (!isEmpty(pinia.auth.userData)) {
        // 사진정보 조회
        if (pinia.auth.isAuth) {
          await pinia.auth.GET_USER_DATA()
        }
        // }
      }
      if (pinia.auth.regInfo.regGnb === '2') {
        if (!pinia.auth.regInfo.termReg && !pinia.auth.regInfo.idDrawal) {
          const saveApi = '/GW/router/STOPLAY/CMN/CmnMember/save'
          const withDrawalParam = {
            cmd: 'D'
          }
          await axios.post(saveApi, { list: [withDrawalParam] }).then(async res => {
            const result = res.data
            if (result.result.code === 'SUCCESS') {
              pinia.auth.regInfo.idDrawal = true
              pinia.auth.regInfo.regGnb = '1'
              console.log('userWithDrawal AuthenticationGuard')
              await pinia.auth.LOGOUT()
              await pinia.auth.CHECK_STATUS(to)
              router.push({ name: 'CM0000M01' })
            } else {
              console.log('userWithDrawal fail AuthenticationGuard')
            }
          })
        }
      }
      console.log('to.meta.requiresAuth', to.meta.requiresAuth)
      // 검증일 경우 미로그인 시 모든 화면 접근 불가
      // if (import.meta.env.VUE_APP_BUILD_MODE === 'STAGING') {
      //   if (
      //     !pinia.auth.isAuth &&
      //     to.name !== 'CM0100M01' &&
      //     to.name !== 'naverAuth' &&
      //     to.name != 'CM0300M01' &&
      //     to.name != 'CM0400M01' &&
      //     to.name != 'CS0105M01' &&
      //     to.name != 'CS0106M01' &&
      //     to.name != 'CS0107M01' &&
      //     !(pinia.auth.errcode === 'network')
      //   ) {
      //     await Vue.$confirm('알림', comT('message.STC_0004'), '취소', '로그인/회원가입', undefined, 'ACTION').then(result => {
      //       if (result == 'secondResult') {
      //         next({ name: 'CM0100M01' })
      //       } else {
      //         next({ name: 'CM0100M01' })
      //       }
      //     })
      //   }
      // } else if (to.meta.requiresAuth && !pinia.auth.isAuth) {
      if (to.meta.requiresAuth && !pinia.auth.isAuth) {
        await Vue.$confirm('알림', comT('message.STC_0004'), '취소', '로그인/회원가입', undefined, 'ACTION').then(result => {
          if (result == 'secondResult') {
            next({ name: 'CM0100M01' })
          } else {
            if (isUndefined(from.name)) {
              next({ name: 'CM0100M01' })
            } else {
              next(false)
            }
          }
        })
      } else {
        console.log('[AuthenticationGuard]', to, from)
      }
      // }
      next()
    })

    // router.afterEach((to, from) => {
    // })

    router.onError(err => {
      console.log(err)
    })

    const getStartDate = async function () {
      const startUrl = '/GW/C1/initSystemTime'
      await axios
        .post(startUrl, {}, { loadingOff: false })
        .then(result => {
          const res = result.data
          if (res.result.code === 'SUCCESS') {
            pinia.auth.startDate = res.data
          } else {
            pinia.auth.startDate = ''
            console.log('[comonCode] error', res.result)
          }
        })
        .catch(err => {
          pinia.auth.isMessage = false
          console.log('[comonCode] error', err)
        })
    }
    const setAnalysisHistory = function (name) {
      const anaysisUrl = '/GW/router/MANAGEMENT/S9/S9000m0/saveInfo'
      const menuId = name.substring(0, name.length - 2) + name.substring(name.length - 1)
      const historyParams = {
        txId: pinia.auth.analysis.key,
        histNo: pinia.auth.analysis.historyNumber,
        menuId: menuId
      }
      try {
        axios
          .post(anaysisUrl, historyParams, { loadingOff: true })
          .then(result => {
            const res = result.data
            if (res.result.code === 'SUCCESS') {
              console.log('history add', res)
            } else {
              console.log('history error')
            }
            pinia.auth.analysis.historyNumber = pinia.auth.analysis.historyNumber + 1
          })
          .catch(err => {
            console.log('[comonCode] error', err)
          })
      } catch (e) {
        console.log('save error', e)
      }
    }
    const setReferId = function (to) {
      if (!isEmpty(to.query.ReferralUrl) && !isUndefined(to.query.ReferralUrl)) {
        pinia.auth.refferId = to.query.ReferralUrl
      }
    }

    const commonMessage = async function (i18n) {
      let isMessage = pinia.auth.isMessage
      console.log('isMessage', isMessage)
      const messageUrl = '/GW/router/MANAGEMENT/C4/C4000M0/list'
      const msgParams = {
        msgCntnt: '',
        msgLang: 'ko',
        sysDomCd: 'STO'
      }
      let koMsg = ko
      if (!isMessage) {
        await axios
          .post(messageUrl, msgParams, { loadingOff: false })
          .then(result => {
            const res = result.data
            if (res.result.code === 'SUCCESS') {
              koMsg.message = {}
              each(res.data, function (item) {
                koMsg.message = {
                  ...koMsg.message,
                  [item.msgId]: item.msgCntnt
                }
              })
              i18n.setLocaleMessage('ko', koMsg)
              pinia.auth.isMessage = true
            } else {
              pinia.auth.isMessage = false
              console.log('[comonCode] error', res.result)
            }
          })
          .catch(err => {
            pinia.auth.isMessage = false
            console.log('[comonCode] error', err)
          })
      }
      console.log('i18n', i18n)
    }
    const commonCode = async function () {
      console.log('[init commonCodePlugin!!!]', Vue, router, pinia)
      const codeUrl = '/GW/router/MANAGEMENT/datacommmon/commonCode/listAll'

      const params = {}
      // if (!pinia.auth.isAuth) return
      if (!yupDefauldLoaded) {
        // yuo 디폴트 메세지 setting
        yup.setLocale({
          mixed: {
            default: '사용할 수 없는 값입니다.',
            required: '필수 입력입니다.',
            // eslint-disable-next-line no-template-curly-in-string
            oneOf: '다음 값 중 하나여야 합니다.: ${values}',
            // eslint-disable-next-line no-template-curly-in-string
            notOneOf: '다음 값 중 하나가 아니어야 합니다.: ${values}',
            notType: function notType(_ref) {
              var path = _ref.path
              var type = _ref.type
              // var value = _ref.value
              // var originalValue = _ref.originalValue
              // var isCast = originalValue !== null && originalValue !== value

              if (type === 'number') {
                return ' 숫자만 입력해주세요.'
              } else if (type === 'date') {
                return ' 날짜 형식으로 입력해주세요.'
              } else {
                return path + ' 항목은 `' + type + '` 형식으로 입력해주세요.'
              }
            },
            defined: '정의되지 않았습니다.'
          },
          string: {
            // eslint-disable-next-line no-template-curly-in-string
            length: '${length}자로 입력해주세요.',
            // eslint-disable-next-line no-template-curly-in-string
            min: '${min}자 이상 입력바랍니다.',
            // eslint-disable-next-line no-template-curly-in-string
            max: '${max}자 까지 입력됩니다.',
            email: '이메일 형식이 아닙니다.'
          }
        })
        yupDefauldLoaded = true
      }
      // 공통코드
      if (!commonCodeLoaded) {
        await axios
          .post(codeUrl, params, { loadingOff: false })
          .then(result => {
            const res = result.data
            if (res.result.code === 'SUCCESS') {
              const codeObj = []
              each(res.data.list, function (item) {
                const itemObj = {
                  cmnCdTypeCd: item.cmnCdTypeCd,
                  detail: item.cmnCdDtl,
                  note: item.note,
                  text: item.cmnCdVal,
                  value: item.cmnCd
                }
                codeObj.push(itemObj)
              })
              pinia.auth.CODE_DATA_UPDATE(codeObj)
              commonCodeLoaded = true
            } else {
              console.log('[comonCode] error', res.result)
            }
          })
          .catch(err => {
            console.log('[comonCode] error', err)
          })
      }
    }
  }
}

export default AuthenticationGuard
