<template>
  <div id="community-quick-menu" class="floating-action-wrap" :class="{ active: toggleMenu }">
    <v-btn icon v-bind="expand" class="floating-action-btn" @click="toggleAction">
      <v-icon class="plus">버튼 더보기</v-icon>
    </v-btn>
    <div class="floating-action-cont" :class="{ hide: hideAction }" @animationend="hideAction == false">
      <v-btn @click="createFeed">
        의견 남기기
        <v-icon class="pen"></v-icon>
      </v-btn>
      <v-btn @click="movePage('/CO/CO0103M01')">
        핀플루언서 찾기
        <v-icon class="find"></v-icon>
      </v-btn>
      <v-btn @click="moveMyActive">
        내 활동로그
        <v-icon class="log"></v-icon>
      </v-btn>
    </div>
  </div>

  <!-- 핀플채널에서 선택 시 -->
  <!--
    <UiBottom title="핀플채널을 선택하세요" :is-open-props="openFinfle" @btm-close="openFinfle = false">
      <v-text-field
      v-model="finfleSrchTxt"
      variant="outlined"
      class="input-item search mt-0"
      clearable
      placeholder="검색어를 입력하세요"
      maxlength="50"
      prepend-inner-icon="search-gray"
      hide-details="auto"
      ></v-text-field>
      <UiSelectChannelList :items="finfleSearchItems" class="mt-6" @selected-user="selectedFinfle" />
    </UiBottom>
  -->

  <!-- 오픈톡에서 선택 시 -->
  <!--
    <UiBottom title="주제를 선택하세요" :is-open-props="openPrjSubject" @btm-close="openPrjSubject = false">
      <UiSelectList :items="categoryList" @selected-item="selectedSubject" />
    </UiBottom>
  -->

  <!-- 커뮤니티 메인화면에서 선택 시 -->
  <UiBottom title="커뮤니티를 선택하세요" :is-open-props="openCom" @btm-close="openCom = false">
    <UiSelectList :items="commuTypeList" variant="line" class="col-2" @selected-item="selectedType" />
  </UiBottom>
</template>
<script>
import { UiBottom, UiSelectList } from '$$bizcomponents'
import { isEmpty, map, find } from 'lodash'

export default {
  name: 'ComQuickMenu',
  components: { UiBottom, UiSelectList },
  inheritAttrs: false,
  props: {
    expand: {
      type: Boolean,
      default: null
    },
    widthObj: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['clickCreate', 'updateExpand'],
  setup() {},
  data() {
    return {
      isExpand: false,
      hideAction: null,
      openCom: false,

      /*
      openFinfle: false,
      openPrjSubject: false,
      topicList: [],
      strongTxt: [],
      finfleSrchTxt: '',
      finfleSearchItems: [],
      intervalObj: false,
      intervalTime: 500,
      categoryList: [],
      */

      commuTypeList: [],
      width: {}
    }
  },
  computed: {
    isAuth() {
      return this.$pinia.auth.isAuth
    },
    siteType() {
      return this.$pinia.com.siteType
    },
    toggleMenu: {
      get() {
        return this.expand ?? this.isExpand
      },
      set(value) {
        this.isExpand = value
        this.$emit('updateExpand', value)
      }
    }
  },
  watch: {
    /*
    finfleSrchTxt() {
      // 검색
      this.startInterval()
    }
    */
  },
  mounted() {
    // 공통코드 조회
    /*
    this.categoryList = this.$pinia.auth.getCommonCode('ST0031') // 오픈톡 주제 코드
    map(this.categoryList, obj => {
      obj.icon = obj.detail
    })
    */
    this.commuTypeList = this.$pinia.auth.getCommonCode('ST0054') // 커뮤니티 종류 코드
    map(this.commuTypeList, obj => {
      obj.icon = obj.note
    })
    /*
    this.strongTxt = this.$pinia.auth.getCommonCode('ST0048') // 강조값
    this.topicList = this.$pinia.auth.getCommonCode('ST0042') // 핀플 카테고리 목록
    map(this.strongTxt, obj => {
      obj.tag = obj.value === '01' ? '<' + obj.note + '>' : obj.value === '02' ? '</' + obj.note + '>' : ''
    })
    */
    window.addEventListener('scroll', this.closeMenu)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.closeMenu)
  },
  /*
  unmounted() {
    this.stopInterval()
  },
  */
  methods: {
    toggleAction() {
      if (!isEmpty(this.hideAction)) {
        this.hideAction = false
      } else {
        this.hideAction = this.toggleMenu
      }
      this.toggleMenu = !this.toggleMenu
    },
    closeMenu() {
      if (this.hideAction != null) {
        // 최초 로딩 후 모션되는 부분 처리
        this.hideAction = true
      }
      this.isExpand = false
    },
    createFeed() {
      this.closeMenu()
      let moveObj = null
      // this.$emit('clickCreate')
      if (this.isAuth) {
        // 조건처리 올리기
        switch (this.siteType) {
          case 'MAIN':
            this.openCom = true
            break
          case 'ALL':
            moveObj = find(this.commuTypeList, { value: 'FIN' })
            if (isEmpty(moveObj)) false
            else this.movePage(moveObj.detail, { open: true })
            break
          case 'MY':
            moveObj = find(this.commuTypeList, { value: 'FIN' })
            if (isEmpty(moveObj)) false
            else this.movePage(moveObj.detail, { open: true })
            break
          case 'OPEN':
            moveObj = find(this.commuTypeList, { value: 'OPEN' })
            if (isEmpty(moveObj)) false
            else this.movePage(moveObj.detail, { open: true })
            break
          default:
            break
        }
      } else {
        this.openLogin()
      }
    },
    openLogin() {
      this.$confirm('알림', this.$t('message.STC_0004'), '취소', '로그인/회원가입', undefined).then(result => {
        if (result == 'secondResult') {
          this.$router.push({ name: 'CM0100M01' })
        }
      })
    },
    movePage(path, param) {
      this.closeMenu()
      this.$router.push({ path: path, state: param })
    },
    moveMyActive() {
      this.closeMenu()
      if (this.isAuth) {
        const userObj = this.$pinia.auth.userData
        this.$router.push({ path: '/CO/CO0104M00', state: { userId: userObj.userId } })
      } else {
        this.openLogin()
      }
    },
    /*
    selectedFinfle(item) {
      // 핀플루언서
      // console.log('핀플루언서 선택!!!', item)
      this.$router.push({ name: 'CO0101M03', state: { baseId: item.userId, baseNickNm: item.nickNm } })
    },
    selectedSubject(item) {
      // 오픈톡 주제
      // console.log('오픈톡 주제 선택!!!', item)
      this.$router.push({ name: 'CO0102M03', state: { postSubjectCd: item.value } })
    },
    */
    selectedType(item) {
      // console.log('커뮤니티 타입 선택!!!', item)
      this.movePage(item.detail)
    }
    /*
    async getFollowList() {
      this.stopInterval()
      // 팔로우 목록 조회
      const srchTxt = this.finfleSrchTxt
      const followApi = '/GW/router/STOPLAY/CMN/CmnMember/followingFinflChannel'
      await this.$http
        .post(followApi, {
          searchWord: srchTxt
        })
        .then(res => {
          const result = res.data
          if (result.result.code === 'SUCCESS') {
            if (!isEmpty(result.data)) {
              let getList = result.data
              this.finfleSearchItems = filter(getList, obj => {
                const findTopic = find(this.topicList, { value: obj.chnlTopicCd })
                obj.topicNm = findTopic.text
                obj.showTopicNm = obj.topicNm
                obj.showNickNm = obj.nickNm

                if (isEmpty(srchTxt) || obj.nickNm.indexOf(srchTxt) > -1 || obj.topicNm.indexOf(srchTxt) > -1) {
                  if (obj.topicNm.indexOf(srchTxt) > -1) {
                  obj.showTopicNm = obj.topicNm.replaceAll(srchTxt, this.strongTxt[0].tag + srchTxt + this.strongTxt[1].tag)
                  } else {
                    obj.showTopicNm = obj.topicNm
                  }
                  if (obj.nickNm.indexOf(srchTxt) > -1) {
                    obj.showNickNm = obj.nickNm.replaceAll(srchTxt, this.strongTxt[0].tag + srchTxt + this.strongTxt[1].tag)
                  } else {
                    obj.showNickNm = obj.nickNm
                  }
                  return obj
                }
              })
            } else {
              // 데이터 미존재 시
              this.finfleTabsItems = []
            }
          } else {
            this.$alert('알림', result.result.clientMessage)
          }
        })
    },
    startInterval() {
      this.stopInterval()
      this.intervalObj = setInterval(() => {
        if (isEmpty(this.finfleSrchTxt)) {
          this.finfleSearchItems = []
          this.stopInterval()
        } else {
          this.getFollowList()
        }
      }, this.intervalTime)
    },
    stopInterval() {
      if (this.intervalObj) {
        clearInterval(this.intervalObj)
      }
    }
    */
  }
}
</script>
<style lang="scss"></style>
