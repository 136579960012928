import { createRouter, createWebHistory } from 'vue-router'
import DeviceUtil from '@/utils/device'
import play from './play/route'

// 라우터를 생성하기 전 채널 접속 정보를 생성합니다
DeviceUtil.detect()
DeviceUtil.detectBrowser()
DeviceUtil.detectOs()
DeviceUtil.detectChannel()
const domainType = DeviceUtil.getDomainType()
console.log('[router] domainType', domainType)

let routes = []
// 로컬에서 사용하는 index.js이므로 가이드 페이지 라우터를 포함시킵니다
routes = routes.concat(play)

// 라우터에 존재하지 않을 경우 404를 발생하도록 선언합니다
routes = routes.concat([
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: 'CM0300M01'
    }
  }
])

// 정리된 라우터를 히스토리 모드로 생성하여 리턴합니다
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  base: '/',
  routes,
  scrollBehavior() {
    // to, from, savedPosition
    // console.log('scrollBehavior', to, from, savedPosition)
    return { left: 0, top: 0 }
  }
})
export default router
