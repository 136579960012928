import { IndexPage, IndexComPage, defaultLayoutComponets, settingLayoutComponets, ComDetailLayoutComponets } from '@/views/layout'
const routes = [
  {
    path: 'MB',
    component: IndexPage,
    children: [
      {
        path: 'MB0100M01',
        name: 'MB0100M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_MB" */ '@/views/MB/MB0100M01.vue') }, defaultLayoutComponets),
        meta: { requiresAuth: false, title: '로그인', description: '로그인' }
      },
      {
        path: 'MB0200M01',
        name: 'MB0200M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_MB" */ '@/views/MB/MB0200M01.vue') }, settingLayoutComponets),
        meta: { requiresAuth: true, title: '마이페이지', description: '마이페이지' }
      },
      // {
      //   path: 'MB0201M01',
      //   name: 'MB0201M01',
      //   components: Object.assign({ content: () => import(/* webpackChunkName: "play_MB" */ '@/views/MB/MB0201M01.vue') }, ComDetailLayoutComponets),
      //   meta: { requiresAuth: true, title: '프로필 설정', description: '프로필 설정' }
      // },
      {
        path: 'MB0202M01',
        name: 'MB0202M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_MB" */ '@/views/MB/MB0202M01.vue') }, defaultLayoutComponets),
        meta: { requiresAuth: true, title: '프로필 편집', description: '프로필 편집' }
      },
      {
        path: 'MB0203M01',
        name: 'MB0203M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_MB" */ '@/views/MB/MB0203M01.vue') }, defaultLayoutComponets),
        meta: { requiresAuth: true, title: '포인트 내역', description: '포인트 내역' }
      },
      {
        path: 'MB0203M02',
        name: 'MB0203M02',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_MB" */ '@/views/MB/MB0203M02.vue') }, defaultLayoutComponets),
        meta: { requiresAuth: true, title: '추천인 내역', description: '추천인 내역' }
      }
    ]
  },
  {
    path: 'MB',
    component: IndexComPage,
    children: [
      {
        path: 'MB0201M01',
        name: 'MB0201M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_MB" */ '@/views/MB/MB0201M01.vue') }, ComDetailLayoutComponets),
        meta: { requiresAuth: true, title: '프로필 설정', description: '프로필 설정' }
      },
    ]
  }
]
export default routes
