import DeviceUtil from '$$utils/device'
import { isUndefined } from 'lodash'
const NativeInterfacePlugin = {
  install: function (Vue) {
    Vue.$nativeApi = Vue.config.globalProperties.$nativeApi = {
      callNativeApi: function (type, cmd, param) {
        return new Promise(function (resolve) {
          const message = {
            command_type: type,
            command: cmd,
            parameters: param === 'N/A' ? { none: 'N/A' } : param
          }
          // 싱글 콜백 방식
          // window.nativeCallbackFn = function (result) {
          //  resolve(result)
          // }
          // 멀티 콜백 방식
          window[cmd + 'NativeCallbackFn'] = function (result) {
            resolve(result)
          }

          if (window.appGate) {
            // Call Android interface
            window.appGate.callNativeApi(JSON.stringify(message))
          } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.appGate) {
            // Call iOS interface
            window.webkit.messageHandlers.appGate.postMessage(message)
          } else {
            // console.log('No native APIs found.')
            resolve('')
            // reject(new Error('No native APIs found.'))
            // throw new Error('No native APIs found.')
          }
        })
      },
      getAppVersion: function () {
        // 앱 버전 받아오기
        return this.callNativeApi('API', 'getAppVersion', 'N/A')
      },
      getDeviceId: function () {
        // Device ID 받아오기.
        return this.callNativeApi('API', 'getDeviceId', 'N/A')
      },
      getDeviceModel: function () {
        // 기기 모델 정보.
        return this.callNativeApi('API', 'getDeviceModel', 'N/A')
      },
      getDeviceOS: function () {
        // 기기 OS 정보.
        return this.callNativeApi('API', 'getDeviceOS', 'N/A')
      },
      getCurrentLoaction: function () {
        // 현 위치 좌표 받아오기
        return this.callNativeApi('API', 'getCurrentLoaction', 'N/A')
      },
      getCurrentAddress: function () {
        // 현 위치 주소 받아오기
        return this.callNativeApi('API', 'getCurrentAddress', 'N/A')
      },
      setSmartARS: function (setValue) {
        // setValue - 'ON' OR 'OFF'
        // 스마트 ARS 설정
        return this.callNativeApi('API', 'setSmartARS', { set: setValue })
      },
      setLocationPermission(setValue) {
        // setValue - 'ON' OR 'OFF'
        // 위치 정보 접근 권한 설정
        return this.callNativeApi('API', 'setLocationPermission', { set: setValue })
      },
      setCameraPermission(setValue) {
        // setValue - 'ON' OR 'OFF'
        // 카메라/사진 접근 권한 설정
        return this.callNativeApi('API', 'setCameraPermission', { set: setValue })
      },
      setPushNotice(setValue) {
        // setValue - 'ON' OR 'OFF'
        // Push 알림 수신 설정
        return this.callNativeApi('API', 'setPushNotice', { set: setValue })
      },
      callDial: function (phoneNo) {
        // 전화 걸기
        return this.callNativeApi('API', 'callDial', { phoneNo: phoneNo })
      },
      showCamera: function () {
        return this.callNativeApi('UI', 'ShowCamera', 'N/A')
      },
      showGallery: function () {
        return this.callNativeApi('UI', 'ShowGallery', 'N/A')
      },
      showIDReader: function () {
        return this.callNativeApi('UI', 'ShowIDReader', 'N/A')
      },
      showVoiceService: function () {
        // 음성변환 서비스
        return this.callNativeApi('UI', 'ShowVoiceService', 'N/A')
      },
      showCertificateManager: function () {
        // 인증서 관리
        return this.callNativeApi('UI', 'ShowCertificateManager', 'N/A')
      },
      showCertificateImport: function (importFrom) {
        // 인증서 가저오기
        // importFrom - 'IMPORT_PC' : PC, 'IMPORT_SMART_PHONE': smart phone
        return this.callNativeApi('UI', 'ShowCertificateImport', { ImportFrom: importFrom })
      },
      showCertificateExport: function (exportTo) {
        // 인증서 내보내기
        // exportTo - 'EXPORT_PC' : PC, 'EXPORT_SMART_PHONE': smart phone
        return this.callNativeApi('UI', 'ShowCertificateExport', { ExportTo: exportTo })
      },
      showTransKeypad: function (keypadType) {
        // 보안 키패트 화면 호출
        // keypadType - 'QUERTY': 쿼티 키패드 , 'NUMBER': 숫자 키 패드
        return this.callNativeApi('UI', 'ShowTransKeypad', { KeypadType: keypadType })
      },
      showChatbot: function () {
        return this.callNativeApi('UI', 'ShowChatbot', 'N/A')
      },
      requestScraping: function (type) {
        // type - 'NICE' 나이스  OR 'SAS' 쿠콘
        return this.callNativeApi('API', 'requestScraping', { Type: type })
      },
      showShareList: function (title, text, url) {
        if (!DeviceUtil.isApp()) {
          if (isUndefined(navigator.share)) {
            return false
          } else {
            navigator.share({
              title: title,
              text: text,
              url: url
            })
            return true
          }
        } else {
          return this.callNativeApi('API', 'requestScraping')
        }
      }
    }
  }
}

export default NativeInterfacePlugin
