import axios from 'axios'
import { isEmpty, startsWith } from 'lodash'
import common from '$$utils/common'
import hmacSHA512 from 'crypto-js/hmac-sha512'
import AES from 'crypto-js/aes'
import Base64 from 'crypto-js/enc-base64'
import UTF8 from 'crypto-js/enc-utf8'
const base64 = function (json) {
  const stringified = JSON.stringify(json)
  // JSON을 문자열화
  const base64Encoded = btoa(unescape(encodeURIComponent(stringified)))
  // 문자열화 된 JSON 을 Base64 로 인코딩
  const paddingRemoved = base64Encoded.replaceAll('=', '')
  // Base 64 의 Padding(= or ==) 을 제거

  return paddingRemoved
}
const AxiosPlugin = {
  install: async function (Vue, router, pinia, options = {}) {
    const ranVal = common.randomValue(89999) + 10000
    console.log('ranVal', ranVal)
    let vestScriptLoaded = false
    const jwtData = function (data) {
      ASEencrypt(data)
      const header = {
        alg: 'HS512',
        typ: 'JWT'
      }
      const t = new Date()
      t.setSeconds(t.getSeconds() + 10)
      const exp = Number(String(t.getTime()).substring(0, 10))
      // const key = pinia.auth.startDate
      const encodedHeader = base64(header)
      const encodedPayload = base64({ exp: exp, jwtTranData: JSON.stringify(data) })
      const signature = Base64.stringify(hmacSHA512(`${encodedHeader}.${encodedPayload}`, pinia.auth.axiosKey))
        .replaceAll('=', '')
        .replaceAll('/', '_')
        .replaceAll('+', '-')
      const jwt = `${encodedHeader}.${encodedPayload}.${signature}`
      console.log('jwt', jwt)
      return jwt
    }
    const ASEencrypt = function (data) {
      const encrypted = AES.encrypt(JSON.stringify(data), import.meta.env.VUE_APP_USE_E2E_KEY).toString()
      console.log('encrypted', encrypted)
      ASEDecrypt(encrypted)
    }
    const ASEDecrypt = function (data) {
      const decrypted = AES.decrypt(data, import.meta.env.VUE_APP_USE_E2E_KEY)
      const decrypted_byte = decrypted.toString(UTF8)

      console.log('decrypted', decrypted_byte)
    }
    // let axios = axios.create()
    // axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
    // axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
    let useE2eWeb = true
    if (options.useE2eWeb !== undefined) {
      useE2eWeb = options.useE2eWeb
    }
    axios.defaults.headers.post['Content-Type'] = 'application/json'
    axios.defaults.transformRequest[0] = (data, headers) => {
      // if (isEmpty(data)) {
      //   return data
      // }
      if (headers['Content-Type'] === 'multipart/form-data') {
        return data
      }
      // console.log('useE2eWeb', useE2eWeb)
      // console.log('useE2eWeb import.meta.env.VUE_APP_USE_E2E_WEB', import.meta.env.VUE_APP_USE_E2E_WEB)
      if (useE2eWeb && headers.novajwtyn !== 'N') {
        return jwtData(data)
      }

      if (typeof data !== 'string') {
        // return qs.stringify(data, { indices: false })
        return JSON.stringify(data, { indices: false })
      }

      return data
    }

    axios.interceptors.request.use(
      config => {
        // MOBILE, PC, APP - set header
        // console.log(config)
        if (config.url && (startsWith(config.url, '/GW') || startsWith(config.url, '/UPLOAD'))) {
          // 로직 변경
        } else {
          config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        }
        if (
          useE2eWeb &&
          !startsWith(config.url, '/GW/C1/initSystemTime') &&
          !startsWith(config.url, '/GW/C1/jwtToken') &&
          !startsWith(config.url, '/GW/C1/logout') &&
          !startsWith(config.url, '/GW/stoplay/login/oauth') &&
          !startsWith(config.url, '/GW/stoplay/proxy/send') &&
          !startsWith(config.url, '/UPLOAD/file/download/FileForJson')
        ) {
          config.headers.novaenyn = 'N'
        } else {
          config.headers.novajwtyn = 'N'
          config.headers.novaenyn = 'N'
        }
        if (config.url !== '/GW/stoplay/login') {
          pinia.auth.axiosKey = import.meta.env.VUE_APP_USE_E2E_KEY + pinia.auth.startDate
        }
        config.headers._csrf = pinia.auth.csrf
        if (isEmpty(pinia.menu.currentMenuItem)) {
          const findMenuInfo = pinia.menu.getItemByPath(router.currentRoute.value.path)
          if (isEmpty(findMenuInfo)) {
            config.headers.novaMenuId = ''
          } else {
            config.headers.novaMenuId = findMenuInfo.menuId
          }
        } else {
          config.headers.novaMenuId = pinia.menu.currentMenuItem.menuId
        }

        return config
      },
      error => {
        // Vue.$loading.hide()
        console.warn('Request Error', error)
        throw error
      }
    )

    axios.interceptors.response.use(
      async response => {
        // console.log('[axios]', response)
        if (response.status !== 200) {
          const error = new Error(response.data.message)
          error.config = response.config
          error.code = Number(response.data.code)
          error.request = response.request
          error.response = response
          console.log('response.status', response.status)
          if (response.status === 500) pinia.auth.errcode = 'network'
          return Promise.reject(error)
        } else {
          if (response.data.size > 0) {
            // blob type data skip
          } else {
            if (!isEmpty(response.data.result)) {
              if (response.data.result.code === 'CE_99023') {
                pinia.auth.LOGOUT({}).then(result => {
                  console.log('[logout]', result)
                  Vue.$alert('알림', response.data.result.clientMessage).then(() => {
                    router.push({ name: 'C10001M01' })
                  })
                })
              }
            }
          }
          if (!isEmpty(response.headers._csrf)) {
            pinia.auth.csrf = response.headers._csrf
          }
          return response
        }
      },
      error => {
        pinia.loading.decrement()
        if (error.message.indexOf('code 500') > -1) {
          pinia.auth.errcode = 'network'
        }
        if (error.message.indexOf('code 404') > -1) {
          pinia.auth.errcode = '404'
        }
        if (pinia.auth.errcode === 'network') {
          router.push({ name: 'CM0400M01' })
        } else if (pinia.auth.errcode === '404') {
          router.push({ name: 'CM0300M01' })
        } else {
          console.log('[axios] error', error)
          throw error
        }
      }
    )

    router.beforeEach(async function (to, from, next) {
      if (!vestScriptLoaded && options.useE2eWeb) {
        var vestScriptUrl = options.e2eWebUrl || '/GW/novaUtil.jsp'
        vestScriptUrl = vestScriptUrl + '?' + ranVal
        await Vue.$loadScript(vestScriptUrl)
        console.log('novaUtil', window.novaUtil)
        if (window.novaUtils !== undefined) {
          vestScriptLoaded = true
        }
      }
      next()
    })

    Vue.$novaAjax = Vue.config.globalProperties.$novaAjax = function (params) {
      let enParam = ''
      console.log('useE2eWeb', useE2eWeb)
      if (typeof params === 'string') {
        enParam = window.novaUtil.en(params)
      } else {
        enParam = window.novaUtil.en(JSON.stringify(params, { indices: false }))
      }

      return enParam
    }
    Vue.$http = Vue.config.globalProperties.$http = axios
  }
}

export default AxiosPlugin
