<template>
  <v-layout id="indexItem" fluid class="responsive">
    <router-view name="aside"></router-view>
    <router-view name="header"></router-view>
    <router-view v-slot="{ Component }" :key="$route.fullPath" name="content">
      <component :is="Component" ref="content" v-resize="resizeCont"></component>
    </router-view>
    <router-view id="quickMenu" name="quick" :width-obj="widthObj"></router-view>
    <router-view name="footer"></router-view>
  </v-layout>
</template>

<script>
import { isEmpty, isUndefined } from 'lodash'
export default {
  name: 'IndexPage',
  components: {},
  setup() {},
  data() {
    return {
      tabs: 'one',
      widthObj: { id: '', widthSize: 0 },
      widthSize: 1
    }
  },
  computed: {
    tabName() {
      return this.$pinia.menu.tabName
    },
    routeName() {
      return this.$route.name
    }
  },
  watch: {
    tabName(val) {
      this.tabs = val
    }
  },
  mounted() {},
  methods: {
    resizeCont() {
      const communityYn = document.getElementById('community-quick-menu')
      if (isUndefined(communityYn)) {
        const width = isEmpty(document.getElementById('quickMenu')) ? 0 : document.getElementById('indexItem').clientWidth
        this.widthSize++
        this.widthObj = { id: 'resize' + this.widthSize, widthSize: width }
      }
    },
    resetSize() {}
  }
}
</script>
<style lang="scss">
@import '@/styles/settings/reset';
</style>
