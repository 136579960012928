import { IndexPage, defaultLayoutComponets, ThermsLayoutComponets } from '@/views/layout'
const routes = [
  {
    path: 'CS',
    component: IndexPage,
    children: [
      {
        path: 'CS0101M01',
        name: 'CS0101M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_CS" */ '@/views/CS/CS0101M01.vue') }, defaultLayoutComponets),
        meta: { requiresAuth: false, title: '공지사항', description: '공지사항' }
      },
      {
        path: 'CS0101M02',
        name: 'CS0101M02',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_CS" */ '@/views/CS/CS0101M02.vue') }, defaultLayoutComponets),
        meta: { requiresAuth: false, title: '공지사항', description: '공지사항 상세' }
      },
      {
        path: 'CS0102M01',
        name: 'CS0102M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_CS" */ '@/views/CS/CS0102M01.vue') }, defaultLayoutComponets),
        meta: { requiresAuth: false, title: '이용약관', description: '이용약관' }
      },
      {
        path: 'CS0103M01',
        name: 'CS0103M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_CS" */ '@/views/CS/CS0103M01.vue') }, defaultLayoutComponets),
        meta: { requiresAuth: false, title: '고객센터', description: '자주묻는 질문 목록' }
      },
      {
        path: 'CS0103M02',
        name: 'CS0103M02',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_CS" */ '@/views/CS/CS0103M02.vue') }, defaultLayoutComponets),
        meta: { requiresAuth: false, title: '자주묻는 질문', description: '자주묻는 질문' }
      },
      {
        path: 'CS0103M03',
        name: 'CS0103M03',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_CS" */ '@/views/CS/CS0103M03.vue') }, defaultLayoutComponets),
        meta: { requiresAuth: true, title: '고객센터', description: '1:1 문의' }
      },
      {
        path: 'CS0103M04',
        name: 'CS0103M04',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_CS" */ '@/views/CS/CS0103M04.vue') }, defaultLayoutComponets),
        meta: { requiresAuth: true, title: '1:1 문의', description: '1:1 문의 상세' }
      },
      // {
      //   path: 'CS0103M05',
      //   name: 'CS0103M05',
      //   components: Object.assign({ content: () => import(/* webpackChunkName: "play_CS" */ '@/views/CS/CS0103M05.vue') }, defaultLayoutComponets),
      //   meta: { requiresAuth: true, title: '문의하기', description: '문의하기' }
      // },
      {
        path: 'CS0104M01',
        name: 'CS0104M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_CS" */ '@/views/CS/CS0104M01.vue') }, defaultLayoutComponets),
        meta: { requiresAuth: true, title: '회원등급', description: '회원등급' }
      },
      {
        path: 'CS0105M01',
        name: 'CS0105M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_CS" */ '@/views/CS/CS0105M01.vue') }, ThermsLayoutComponets),
        meta: { requiresAuth: false, title: '서비스 이용약관', description: '서비스 이용약관' }
      },
      {
        path: 'CS0106M01',
        name: 'CS0106M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_CS" */ '@/views/CS/CS0106M01.vue') }, ThermsLayoutComponets),
        meta: { requiresAuth: false, title: '개인정보 수집이용 동의서', description: '개인정보 수집이용 동의서' }
      },
      {
        path: 'CS0107M01',
        name: 'CS0107M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_CS" */ '@/views/CS/CS0107M01.vue') }, defaultLayoutComponets),
        meta: { requiresAuth: false, title: '개인정보처리방침', description: '개인정보처리방침' }
      }
    ]
  }
]
export default routes
