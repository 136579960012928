const LoadScript = {
  install: function (Vue) {
    Vue.$loadScript = Vue.config.globalProperties.$loadScript = function (src, asyncOption, deferOption) {
      // eslint-disable-line no-param-reassign
      return new Promise(function (resolve, reject) {
        if (document.querySelector('script[src="' + src + '"]')) {
          resolve()
          return
        }

        const el = document.createElement('script')
        el.type = 'text/javascript'

        if (asyncOption !== undefined) {
          if (asyncOption === true) {
            el.async = true
          }
        } else {
          el.async = true
        }
        if (deferOption !== undefined && deferOption === true) {
          el.defer = true
        }

        el.src = src

        el.addEventListener('load', resolve)
        el.addEventListener('error', reject)
        el.addEventListener('abort', reject)

        document.head.appendChild(el)
      })
    }

    Vue.$loadScriptClose = Vue.config.globalProperties.$loadScriptClose = function (src, asyncOption, deferOption) {
      // eslint-disable-line no-param-reassign
      return new Promise(function (resolve) {
        if (document.querySelector('script[src="' + src + '"]')) {
          resolve()
          return
        }

        const el = document.createElement('script')
        el.type = 'text/javascript'

        if (asyncOption !== undefined) {
          if (asyncOption === true) {
            el.async = true
          }
        } else {
          el.async = true
        }
        if (deferOption !== undefined && deferOption === true) {
          el.defer = true
        }

        el.src = src

        el.addEventListener('load', resolve)
        el.addEventListener('error', resolve)
        el.addEventListener('abort', resolve)

        document.head.appendChild(el)
      })
    }

    Vue.$unloadScript = Vue.config.globalProperties.$unloadScript = function (src) {
      // eslint-disable-line no-param-reassign
      return new Promise(function (resolve) {
        const el = document.querySelector('script[src="' + src + '"]')

        if (!el) {
          // eslint-disable-next-line prefer-promise-reject-errors
          // reject('scriptNotFound')
          resolve()
          return
        }
        document.head.removeChild(el)

        resolve()
      })
    }
  }
}
export default LoadScript
