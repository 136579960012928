<template>
  <v-app-bar>
    <v-card>
      <v-tabs v-model="tabs">
        <v-tab value="one">Item One</v-tab>
        <v-tab value="two">Item Two</v-tab>
        <v-tab value="three">Item Three</v-tab>
        <v-tab value="four">Item four</v-tab>
        <v-tab value="five">Item five</v-tab>
        <v-spacer></v-spacer>
      </v-tabs>
    </v-card>
    <v-spacer></v-spacer>
    <v-btn icon>
      <v-icon>mdi-close-box-outline</v-icon>
    </v-btn>
  </v-app-bar>
</template>
<script>
export default {
  name: 'TabNav',
  components: {},
  props: {},
  setup() {},
  data() {
    return {
      tabs: 'one'
    }
  },
  computed: {},
  watch: {
    tabs(val) {
      this.$pinia.menu.tabName = val
    }
  },
  mounted() {},
  unmounted() {},
  methods: {}
}
</script>
