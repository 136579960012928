import axios from 'axios'
const CommonLoadingPlugin = {
  install: function (Vue, pinia) {
    axios.interceptors.request.use(
      function (config) {
        if (!config.loadingOff) {
          if (Vue.config.globalProperties.$modal.dynamicModals.length === 0) {
            Vue.$loading.show()
          }
        }
        return config
      },
      function (error) {
        Vue.$loading.hide()
        throw error
      }
    )

    axios.interceptors.response.use(
      function (response) {
        if (String(response) === 'undefined') {
          Vue.$loading.hide()
        } else if (String(response.config.loadingOff) === 'undefined') {
          Vue.$loading.hide()
        } else {
          if (!response.config.loadingOff) {
            Vue.$loading.hide()
          }
        }
        return response
      },
      function (error) {
        Vue.$loading.hide()
        throw error
      }
    )

    Vue.$loading = Vue.config.globalProperties.$loading = {
      show: function () {
        pinia.loading.increment(1)
        console.log('loadingCnt', pinia.loading.loadingCnt)
      },
      hide: function () {
        pinia.loading.decrement(-1)
        console.log('loadingCnt', pinia.loading.loadingCnt)
      },
      hideAll: function () {
        pinia.loading.decrement()
        console.log('loadingCnt', pinia.loading.loadingCnt)
      }
    }
  }
}

export default CommonLoadingPlugin
