const DeviceUtil = {
  type: null,
  browser: null,
  ostype: null,
  nativeVer: null,
  channelType: null,
  osVersion: null,
  TYPE_DESKTOP: 'desktop',
  TYPE_PHONE: 'phone',
  TYPE_TABLET: 'tablet',
  BROWSER_OPERA: 'opera',
  BROWSER_MSIE: 'msie',
  BROWSER_SAFARI: 'safari',
  BROWSER_CHROME: 'chrome',
  BROWSER_MSIE10LT: 'msie10lt',
  BROWSER_KAKAO: 'kakao',
  BROWSER_ETC: 'etc',
  OS_ANDROID: 'android',
  OS_IOS: 'ios',
  OS_WINDOW: 'window',
  OS_UNIX: 'unix',
  OS_ETC: 'etc',
  ipv4Maybe: /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/,
  ipv6Block: /^[0-9A-F]{1,4}$/i,
  initType: function (typeVal) {
    this.type = typeVal
  },
  initBrower: function (browserVal) {
    this.browser = browserVal
  },
  initOs: function (osVal) {
    this.ostype = osVal
  },
  initOsVersion: function (osVal) {
    this.osVersion = osVal
  },
  detect: function () {
    if (!this.type) {
      const c = window.navigator.userAgent
      const d = /iP(hone|od|ad)|Android|IEMobile|Mobile Safari|Opera M(ini|obi)|Windows (CE|Phone)|Nokia|BlackBerry/i.test(c)
      const b = /iPad/i.test(c)
      if (d) {
        const e = c.toLowerCase()
        if (e.indexOf('android') > -1) {
          if (e.indexOf('mobile') === -1) {
            const a = /SHV-E120/i.test(c)
            if (!a) {
              this.initType(this.TYPE_TABLET)
            }
          }
        }
        if (!this.type) {
          this.initType(this.TYPE_PHONE)
        }
      }
      if (b) {
        this.initType(this.TYPE_TABLET)
      }
      if (!this.type) {
        this.initType(this.TYPE_DESKTOP)
      }
    }
    return this.type
  },
  isMobile: function () {
    return this.type !== this.TYPE_DESKTOP
  },
  isTablet: function () {
    return this.type === this.TYPE_TABLET
  },
  isDesktop: function () {
    return this.type === this.TYPE_DESKTOP
  },
  isIe: function () {
    return this.browser === this.BROWSER_MSIE10LT || this.browser === this.BROWSER_MSIE
  },
  isIos: function () {
    return this.ostype === this.OS_IOS
  },
  isAndorid: function () {
    return this.ostype === this.OS_ANDROID
  },
  isApp: function () {
    return false
  },
  detectBrowser: function () {
    if (!this.browser) {
      const userAgent = window.navigator.userAgent
      if (userAgent.indexOf('KAKAOTALK') > 0) {
        this.initBrower(this.BROWSER_KAKAO)
      } else if (navigator.appName === 'Opera') {
        this.initBrower(this.BROWSER_OPERA)
      } else if (
        userAgent.indexOf('MSIE 6') > 0 ||
        userAgent.indexOf('MSIE 7') > 0 ||
        userAgent.indexOf('MSIE 8') > 0 ||
        userAgent.indexOf('MSIE 9') > 0
      ) {
        this.initBrower(this.BROWSER_MSIE10LT)
      } else if (userAgent.indexOf('Edg') > 0) {
        this.initBrower(this.BROWSER_MSIE)
      } else if (userAgent.indexOf('Chrome') > 0) {
        this.initBrower(this.BROWSER_CHROME)
      } else if (userAgent.indexOf('Safari') > 0) {
        this.initBrower(this.BROWSER_SAFARI)
      } else {
        this.initBrower(this.BROWSER_ETC)
      }
    }
    return this.browser
  },
  detectOs: function () {
    if (!this.ostype) {
      const userAgent = window.navigator.userAgent.toLowerCase()
      // const osVersion = window.navigator.userAgent.match(/\((.*)\)(.*)\((.*)\)/i)
      // this.initOsVersion(osVersion[1])
      // console.log('userAgent', userAgent, osVersion)
      if (typeof window !== 'undefined' && window.navigator && window.navigator.platform && /ip(ad|hone|od)/.test(window.navigator.platform)) {
        this.initOs(this.OS_IOS)
      } else if (typeof window !== 'undefined' && window.navigator && /ip(ad|hone|od)/.test(userAgent)) {
        this.initOs(this.OS_IOS)
      } else if (userAgent.indexOf('linux') > 0 || userAgent.indexOf('android') > 0) {
        this.initOs(this.OS_ANDROID)
      } else if (userAgent.indexOf('windows') > 0 || userAgent.indexOf('iemobile') > 0) {
        this.initOs(this.OS_WINDOW)
      } else if (userAgent.indexOf('x11') > 0) {
        this.initOs(this.OS_UNIX)
      } else {
        this.initOs(this.OS_ETC)
      }
      if (userAgent.indexOf('cretop') > 0) {
        this.setNativeVersion('cretop')
      }
    }

    return this.ostype
  },
  isIP: function (str) {
    let version = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ''
    version = String(version)
    if (!version) {
      return this.isIP(str, 4) || this.isIP(str, 6)
    } else if (version === '4') {
      if (!this.ipv4Maybe.test(str)) {
        return false
      }
      const parts = str.split('.').sort(function (a, b) {
        return a - b
      })
      return parts[3] <= 255
    } else if (version === '6') {
      const blocks = str.split(':')
      let foundOmissionBlock = false // marker to indicate ::

      // At least some OS accept the last 32 bits of an IPv6 address
      // (i.e. 2 of the blocks) in IPv4 notation, and RFC 3493 says
      // that '::ffff:a.b.c.d' is valid for IPv4-mapped IPv6 addresses,
      // and '::a.b.c.d' is deprecated, but also valid.
      const foundIPv4TransitionBlock = this.isIP(blocks[blocks.length - 1], 4)
      const expectedNumberOfBlocks = foundIPv4TransitionBlock ? 7 : 8

      if (blocks.length > expectedNumberOfBlocks) {
        return false
      }
      // initial or final ::
      if (str === '::') {
        return true
      } else if (str.substr(0, 2) === '::') {
        blocks.shift()
        blocks.shift()
        foundOmissionBlock = true
      } else if (str.substr(str.length - 2) === '::') {
        blocks.pop()
        blocks.pop()
        foundOmissionBlock = true
      }

      for (let i = 0; i < blocks.length; ++i) {
        // test for a :: which can not be at the string start/end
        // since those cases have been handled above
        if (blocks[i] === '' && i > 0 && i < blocks.length - 1) {
          if (foundOmissionBlock) {
            return false // multiple :: in address
          }
          foundOmissionBlock = true
        } else if (foundIPv4TransitionBlock && i === blocks.length - 1);
        else if (!this.ipv6Block.test(blocks[i])) {
          return false
        }
      }
      if (foundOmissionBlock) {
        return blocks.length >= 1
      }
      return blocks.length === expectedNumberOfBlocks
    }
    return false
  },
  getDomainType: function () {
    console.log('PC DOMAIN PREFIX : ', import.meta.env.VUE_APP_DOMAIN_PC_PREFIX)
    console.log('MOBILE DOMAIN PREFIX : ', import.meta.env.VUE_APP_DOMAIN_MOBILE_PREFIX)

    const hostName = window.location.hostname

    let domainType = 'pc'

    // ip connect route to pc
    if (this.isIP(hostName)) {
      domainType = 'pc'
    } else {
      const hostNames = hostName.split('.')

      if (hostNames.length < 3) {
        // ex) http://app.kr
        domainType = 'pc'
      } else {
        if (hostNames[0].toLowerCase() === import.meta.env.VUE_APP_DOMAIN_PC_PREFIX.toLowerCase()) {
          domainType = 'pc'
        } else if (hostNames[0].toLowerCase() === import.meta.env.VUE_APP_DOMAIN_MOBILE_PREFIX.toLowerCase()) {
          domainType = 'mobile'
        }
      }
    }
    return domainType
  },
  setNativeVersion(version) {
    this.nativeVer = version
  },
  getNativeVersion() {
    return this.nativeVer
  },
  isNativeApp() {
    if (this.nativeVer !== undefined && this.nativeVer !== null && this.nativeVer !== '') {
      return true
    }
    return false
  },
  detectChannel: function () {
    if (this.isNativeApp()) {
      this.channelType = '03'
    } else if (this.isDesktop()) {
      this.channelType = '02'
    } else {
      if (this.isTablet()) {
        this.channelType = '03'
      } else {
        this.channelType = '03'
      }
    }
  }
}

export default DeviceUtil
