<template>
  <v-app-bar class="header">
    <div class="header-menu-left">
      <button class="prev-btn" title="이전단계" @click="goBack()"></button>
      <p class="menu-tit">{{ menuName }}</p>
    </div>

    <div class="header-menu-right">
      <!-- 로그인 프로필 이미지, 비로그인 아이콘 -->
      <button class="my-profile" title="프로필" @click="moveLogin()">
        <UiUserImg v-if="isAuth" :key="isAuth" :data="userData"></UiUserImg>
        <button v-else class="my-page"></button>
      </button>
      <button class="btn-menu" @click="activeAsideClick()">메뉴</button>
    </div>
  </v-app-bar>
</template>
<script>
import { isEmpty } from 'lodash'
import { UiUserImg } from '$$bizcomponents'
export default {
  name: 'HeaderPage',
  components: { UiUserImg },
  data() {
    return {
      tabs: 'one'
    }
  },
  computed: {
    isMobile() {
      return this.$pinia.popup.mode === 'mobile'
    },
    headerName() {
      if (this.isMobile) {
        return '두부분식'
      } else {
        return '세상에 이런 폰트가 나오다니 천재인듯'
      }
    },
    menuName() {
      let pageInfo = this.$router.currentRoute.value.meta
      return pageInfo.title
    },
    isAuth() {
      return this.$pinia.auth.isAuth
    },
    userData() {
      return this.$pinia.auth.userData
    }
  },
  watch: {},
  mounted() {},
  methods: {
    activeAsideClick() {
      this.$pinia.popup.asideActive = !this.$pinia.popup.asideActive
    },
    moveLogin() {
      if (this.isAuth) {
        this.movePage('/MB/MB0200M01')
      } else {
        this.movePage('/CM/CM0100M01')
      }
    },
    movePage(path, name) {
      if (!isEmpty(path)) {
        this.$router.push({ path: path })
      } else {
        this.$router.push({ name: name, query: {}, state: {} })
      }
    },
    goBack() {
      let history = ''
      try {
        history = this.$router.options.history.state.back
      } catch (error) {
        this.movePage('/')
      }
      if (isEmpty(history)) {
        this.movePage('/')
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/settings/reset';
</style>
