import { IndexPage, IndexContentPage, IndexComPage, simpleLayoutComponets, mainLayoutComponets, ErrorLayoutComponets } from '@/views/layout'
const routes = [
  {
    path: 'CM',
    component: IndexComPage,
    children: [
      {
        path: 'CM0000M01',
        name: 'CM0000M01',
        alias: '/',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_CM" */ '@/views/CM/CM0000M01.vue') }, mainLayoutComponets),
        meta: { requiresAuth: false, title: '메인', description: '투자정보 커뮤니티 두부분식에서 많은 정보를 공유하세요.' }
      }
    ]
  },
  {
    path: 'CM',
    component: IndexPage,
    children: [
      {
        path: 'CM0100M01',
        name: 'CM0100M01',
        // alias: '/',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_CM" */ '@/views/CM/CM0100M01.vue') }, simpleLayoutComponets),
        meta: { requiresAuth: false, title: '로그인', description: '로그인' }
      },
      // {
      //   path: 'CM0100M02',
      //   name: 'CM0100M02',
      //   components: Object.assign({ content: () => import(/* webpackChunkName: "play_CM" */ '@/views/CM/content.vue') }, defaultLayoutComponets),
      //   meta: { requiresAuth: false, title: 'content', description: 'content' }
      // },
      {
        path: 'CM0300M01',
        name: 'CM0300M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_CM" */ '@/views/CM/CM0300M01.vue') }, ErrorLayoutComponets),
        meta: { requiresAuth: false, title: '에러 404', description: '에러 404' }
      },
      {
        path: 'CM0400M01',
        name: 'CM0400M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_CM" */ '@/views/CM/CM0400M01.vue') }, ErrorLayoutComponets),
        meta: { requiresAuth: false, title: '에러 500', description: '에러 500' }
      }
    ]
  },
  {
    path: 'CM',
    component: IndexContentPage,
    children: [
      {
        path: 'naverAuth',
        name: 'CM0100M02',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_CM" */ '@/views/CM/naverAuth.vue') }),
        meta: { requiresAuth: false, title: '네이버 인증', description: '네이버 인증' }
      },
      {
        path: 'kakaoAuth',
        name: 'CM0100M03',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_CM" */ '@/views/CM/kakaoAuth.vue') }),
        meta: { requiresAuth: false, title: '카카오 인증', description: '카카오 인증' }
      }
    ]
  }
]
export default routes
