<template>
  <v-app-bar class="header">
    <button class="btn-main-logo" @click="movePage('/')">
      <img class="main-logo" src="/assets/main/logo.png" alt="두부분식" />
    </button>
  </v-app-bar>
</template>
<script>
import { isEmpty } from 'lodash'
export default {
  name: 'ErrorHeader',
  components: {},
  data() {
    return {
      tabs: 'one',
      userData: {}
    }
  },
  computed: {
    isMobile() {
      return this.$pinia.popup.mode === 'mobile'
    },
    headerName() {
      if (this.isMobile) {
        return '두부분식'
      } else {
        return '세상에 이런 폰트가 나오다니 천재인듯'
      }
    },
    menuName() {
      let pageInfo = this.$router.currentRoute.value.meta
      return pageInfo.title
    }
  },
  watch: {},
  mounted() {},
  methods: {
    activeAsideClick() {
      this.$pinia.popup.asideActive = !this.$pinia.popup.asideActive
    },
    movePage(path, name) {
      console.log('[pageMove]', path)
      if (!isEmpty(path)) {
        this.$router.push({ path: path })
      } else {
        this.$router.push({ name: name, query: {}, state: {} })
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/settings/reset';
</style>
