<template>
  <!-- <button class="top-scroll aaa" @click="goTop()"><v-icon dark> mdi-arrow-up</v-icon></button>
  :style="'{left: calc(50% + ' + parentWidth + '- 36px - 24px)}'" 
  -->
  <v-btn class="topscroll leftWidth" :style="quickShow ? 'display:block' : 'display:none'" :fixed="true" @click="goTop()">
    <v-icon dark> mdi-arrow-up</v-icon>
  </v-btn>
</template>
<script>
import { isEmpty } from 'lodash'
export default {
  name: 'QuickMenu',
  components: {},
  props: {
    widthObj: {
      type: Object,
      default: () => {}
    }
  },
  setup() {},
  data() {
    return {
      targetElement: null,
      parentWidth: 200,
      windowTop: 0
    }
  },
  computed: {
    quickShow() {
      if (this.windowTop === 0) return false
      else return true
    }
  },
  watch: {
    widthObj: {
      handler: function () {
        if (isEmpty(this.widthObj)) {
          this.parentWidth = 0
        } else {
          this.parentWidth = this.widthObj.widthSize / 2 + 'px'
        }
      },
      deep: true
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll) // TOP일때 quickMenu 제거

    const targetEl = document.querySelector('#quickMenuButton > .v-fab__container')
    if (targetEl) {
      this.targetElement = targetEl
    } else {
      this.targetElement = 'cursor'
    }
    console.log('targetEl', targetEl)
  },
  unmounted() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll() {
      this.windowTop = window.top.scrollY
    },
    goTop() {
      console.log('goTop!!!')
      const moveItem = document.getElementsByClassName('v-layout')
      if (!isEmpty(moveItem)) {
        moveItem[0].scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
  }
}
</script>
<style lang="scss">
.leftWidth {
  left: calc(50% + v-bind(parentWidth) - 36px - 24px) !important;
}
</style>
