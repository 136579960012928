<template>
  <v-app-bar class="header search-header">
    <div class="header-menu-left">
      <button class="prev-btn" title="이전단계" @click="goBack()"></button>
      <v-text-field v-model="typingText" class="input-item" variant="plain" clearable :placeholder="setPlaceholder" maxlength="50" hide-details>
        <template #prepend-inner>
          <template v-if="autoSearch">
            <v-icon class="search-white"></v-icon>
          </template>
          <template v-else> # </template>
        </template>
      </v-text-field>
      <v-btn v-if="!autoSearch" :disabled="!typingText" icon class="ml-n2" size="small" @click="search">
        <v-icon class="search-white"></v-icon>
      </v-btn>
    </div>
  </v-app-bar>
</template>
<script>
import { isEmpty, find } from 'lodash'
export default {
  name: 'SearchHeader',
  components: {},
  data() {
    return {
      typingText: '',

      intervalObj: false,
      intervalTime: 500
    }
  },
  computed: {
    menuName() {
      let pageInfo = this.$router.currentRoute.value.meta
      return pageInfo.title
    },
    isAuth() {
      return this.$pinia.auth.isAuth
    },

    searchType() {
      return this.$pinia.com.searchType
    },
    searchTxt() {
      return this.$pinia.com.searchTxt
    },
    autoSearch() {
      const findObj = find(this.$pinia.com.searchTypeList, { value: this.searchType })
      return isEmpty(findObj) ? false : findObj.text === 'Y'
    },
    setPlaceholder() {
      const findObj = find(this.$pinia.com.searchTypeList, { value: this.searchType })
      return isEmpty(findObj) ? '' : findObj.note
    }
  },
  watch: {
    typingText() {
      if (this.autoSearch) {
        this.startInterval('intervalObj')
      }
    }
  },
  mounted() {
    // this.$pinia.com.SITE_TYPE_LIST_UPDATE(this.$pinia.auth.getCommonCode('ST0052'))
    this.$pinia.com.SEARCH_TYPE_LIST_UPDATE(this.$pinia.auth.getCommonCode('ST0053'))
    this.typingText = this.searchTxt
  },
  unmounted() {
    this.stopInterval()
  },
  methods: {
    activeAsideClick() {
      this.$pinia.popup.asideActive = !this.$pinia.popup.asideActive
    },
    moveLogin() {
      if (this.isAuth) {
        this.movePage('/MB/MB0200M01')
      } else {
        this.movePage('/CM/CM0100M01')
      }
    },
    movePage(path, name) {
      if (!isEmpty(path)) {
        this.$router.push({ path: path })
      } else {
        this.$router.push({ name: name, query: {}, state: {} })
      }
    },
    goBack() {
      let history = ''
      try {
        history = this.$router.options.history.state.back
      } catch (error) {
        this.movePage('/')
      }
      if (isEmpty(history)) {
        this.movePage('/')
      } else {
        this.$router.go(-1)
      }
    },
    // 검색 버튼 존재 시
    search() {
      this.$pinia.com.SEARCH_TEXT_UPDATE(this.typingText)
    },
    // 자동 검색
    searchAuto() {
      this.$pinia.com.SEARCH_TEXT_UPDATE(this.typingText)
      this.stopInterval()
    },
    startInterval() {
      this.stopInterval(this.intervalObj)
      this.intervalObj = setInterval(() => {
        this.searchAuto()
      }, this.intervalTime)
    },
    stopInterval() {
      if (this.intervalObj) {
        clearInterval(this.intervalObj)
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/settings/reset';
</style>
