<template>
  <router-view name="content"></router-view>
</template>

<script>
export default {
  name: 'IndexPage',
  components: {},
  emits: [],
  setup() {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  mounted() {
    document.body.classList.add('app-default')
  },
  methods: {}
}
</script>

<style lang="scss">
  @import '@/styles/settings/reset';
</style>
