import { UiModal, UiModalContent, UiModalHeader } from '$$components/modal'
import { find, isEmpty } from 'lodash'
import common from '$$utils/common'
// 모달을 총괄하는 plugin으로
// alert confirm modal로 구분한다
// modal은 dynamic Content를 제공하고
// layer팝업이 열리고 닫히는 모든 역활은 공통으로 제공한다
const CommonModalPlugin = {
  install: function (Vue, VModal, pinia, router) {
    router.beforeEach(function (to, from, next) {
      if (Vue.config.globalProperties.$modal.dynamicModals.length > 0) {
        Vue.closeModals()
        next()
        return
      }
      next()
    })

    Vue.$alert = Vue.config.globalProperties.$alert = function (title, body, confirmButtonName, iconName, btnClassName, iconClassName) {
      if (body === undefined) {
        body = title
        title = ''
      }
      const ranVal = common.randomValue(89999) + 10000
      var bind = {
        name: null,
        value: false,
        ssr: true,
        classes: false,
        overlayClass: ['v-overlay', 'v-dialog'],
        contentClass: ['v-overlay__content'],
        styles: {},
        overlayStyle: {},
        contentStyle: {},
        lockScroll: false,
        hideOverlay: false,
        clickToClose: true,
        escToClose: true,
        preventClick: false,
        attach: false,
        transition: 'vfm',
        overlayTransition: 'vfm',
        zIndexAuto: true,
        zIndexBase: '1200',
        zIndex: false,
        focusRetain: false,
        focusTrap: true,
        fitParent: true,
        drag: false,
        dragSelector: '',
        keepChangedStyle: false,
        resize: false,
        resizeDirections: ['t', 'tr', 'r', 'br', 'b', 'bl', 'l', 'tl'],
        minWidth: 0,
        minHeight: 0,
        maxWidth: 0,
        maxHeight: 0
      }
      var param = {
        activeBtnClose: true,
        activeBtnBack: false,
        isAlert: false,
        isConfirm: false,
        cancelButtonName: '취소',
        confirmButtonName: !confirmButtonName ? '확인' : confirmButtonName,
        isIcon: !iconName ? false : true,
        iconName: iconName,
        btnClassName: btnClassName,
        iconClassName: iconClassName
      }
      return new Promise(function (resolve) {
        const prevElement = document.activeElement
        bind.name = 'common-alert-' + ranVal
        // bind.contentStyle = { width: '400px' }
        param.isAlert = true

        const modalParams = find(Vue.config.globalProperties.$modal.dynamicModals, function (item) {
          return item.bind.name === bind.name
        })

        if (!isEmpty(modalParams)) {
          Vue.config.globalProperties.$modal.hide(modalParams.name)
        }

        Vue.config.globalProperties.$modal.show({
          component: UiModal,
          bind: {
            ...bind
          },
          params: {
            ...param
          },
          on: {
            // event by v-modal
            confirm(close, result) {
              resolve(result)
              close()
            },
            cancel(close, result) {
              resolve(result)
              close()
            },
            // event by vue-final-modal
            // 'click-outside' () {
            //   console.log('@click-outside')
            // },
            // 'before-open' () {
            //   console.log('@before-open')
            // },
            // opened () {
            //   console.log('@opened')
            // },
            'before-close': function (event) {
              console.log('[modal] before-close', prevElement, event)
            },
            closed(result) {
              console.log(
                '@closed prevElement',
                result,
                prevElement,
                document.body.contains(prevElement),
                prevElement.nodeName.toLowerCase() !== 'body'
              )
              if (prevElement.nodeName.toLowerCase() !== 'body' && document.body.contains(prevElement)) {
                prevElement.focus()
              }
            }
          },
          slots: {
            /* 20240906 title 제거 : 문구로 대체
            title: {
              component: UiModalHeader,
              bind: {
                text: title
              }
            },
            */
            default: {
              component: UiModalContent,
              bind: {
                content: body
              }
            }
          }
        })
      })
    }

    Vue.$confirm = Vue.config.globalProperties.$confirm = function (
      title,
      body,
      cancelButtonName,
      confirmButtonName,
      className,
      iconName,
      btnClassName,
      iconClassName
    ) {
      const ranVal = common.randomValue(89999) + 10000
      var bind = {
        name: null,
        value: false,
        ssr: true,
        classes: false,
        overlayClass: ['v-overlay', 'v-dialog'],
        contentClass: ['v-overlay__content'],
        styles: {},
        overlayStyle: {},
        contentStyle: {},
        lockScroll: false,
        hideOverlay: false,
        clickToClose: false,
        escToClose: false,
        preventClick: false,
        attach: false,
        transition: 'vfm',
        overlayTransition: 'vfm',
        zIndexAuto: true,
        zIndexBase: '1200',
        zIndex: false,
        focusRetain: false,
        focusTrap: true,
        fitParent: true,
        drag: false,
        dragSelector: '',
        keepChangedStyle: false,
        resize: false,
        resizeDirections: ['t', 'tr', 'r', 'br', 'b', 'bl', 'l', 'tl'],
        minWidth: 0,
        minHeight: 0,
        maxWidth: 0,
        maxHeight: 0
      }
      var param = {
        activeBtnClose: true,
        activeBtnBack: false,
        isAlert: false,
        isConfirm: false,
        cancelButtonName: '취소',
        confirmButtonName: '확인',
        className: '',
        isIcon: !iconName ? false : true,
        iconName: iconName,
        btnClassName: btnClassName,
        iconClassName: iconClassName
      }
      return new Promise(function (resolve) {
        const prevElement = document.activeElement
        bind.name = 'common-confirm-' + ranVal
        // bind.contentStyle = { width: '400px' }
        param.isConfirm = true
        param.cancelButtonName = cancelButtonName !== undefined ? cancelButtonName : '취소'
        param.confirmButtonName = confirmButtonName !== undefined ? confirmButtonName : '확인'
        param.className = className !== undefined ? className : ''
        param.iconName = iconName !== undefined ? iconName : ''
        param.btnClassName = btnClassName !== undefined ? btnClassName : ''
        param.iconClassName = iconClassName !== undefined ? iconClassName : ''

        const modalParams = find(Vue.config.globalProperties.$modal.dynamicModals, function (item) {
          return item.bind.name === bind.name
        })

        if (!isEmpty(modalParams)) {
          Vue.config.globalProperties.$modal.hide(modalParams.name)
        }

        console.log('[confirm open!!!::: ]', bind, param)

        Vue.config.globalProperties.$modal.show({
          component: UiModal,
          bind: {
            ...bind
          },
          params: {
            ...param
          },
          on: {
            // event by v-modal
            confirm(close, result) {
              resolve(result)
              close()
            },
            cancel(close, result) {
              resolve(result)
              close()
            },
            // event by vue-final-modal
            // 'click-outside' () {
            //   console.log('@click-outside')
            // },
            // 'before-open' () {
            //   console.log('@before-open')
            // },
            // opened () {
            //   console.log('@opened')
            // },
            'before-close': function (event) {
              console.log('[modal] before-close', prevElement, event)
            },
            closed(result) {
              console.log(
                '@closed prevElement',
                result,
                prevElement,
                document.body.contains(prevElement),
                prevElement.nodeName.toLowerCase() !== 'body'
              )
              if (prevElement.nodeName.toLowerCase() !== 'body' && document.body.contains(prevElement)) {
                prevElement.focus()
              }
            }
          },
          slots: {
            /* 20240906 title 제거 : 문구로 대체
            title: {
              component: UiModalHeader,
              bind: {
                text: title
              }
            },
            */
            default: {
              component: UiModalContent,
              bind: {
                content: body
              }
            }
          }
        })
      })
    }

    Vue.modalOpen = Vue.config.globalProperties.$modalOpen = async function (title, vueComponent, { params = {} }) {
      const ranVal = common.randomValue(89999) + 10000
      var bind = {
        name: null,
        value: false,
        ssr: true,
        classes: false,
        overlayClass: [],
        contentClass: [],
        styles: {},
        overlayStyle: {},
        contentStyle: {},
        lockScroll: false,
        hideOverlay: false,
        clickToClose: false,
        escToClose: false,
        preventClick: false,
        attach: false,
        transition: 'vfm',
        overlayTransition: 'vfm',
        zIndexAuto: true,
        zIndexBase: '1200',
        zIndex: false,
        focusRetain: false,
        focusTrap: true,
        fitParent: true,
        drag: false,
        dragSelector: '',
        keepChangedStyle: false,
        resize: false,
        resizeDirections: ['t', 'tr', 'r', 'br', 'b', 'bl', 'l', 'tl'],
        minWidth: 0,
        minHeight: 0,
        maxWidth: 0,
        maxHeight: 800
      }
      var param = {
        activeBtnClose: params.activeBtnClose !== undefined ? params.activeBtnClose : true,
        activeBtnBack: false,
        isTitle: params.isTitle !== undefined ? params.isTitle : false,
        isAlert: false,
        isConfirm: false,
        cancelButtonName: '취소',
        confirmButtonName: '확인',
        width: params.width,
        title: title,
        modalName: params.modalName,
        className: params.className !== undefined ? params.className : '',
        headerClass: params.headerClass !== undefined ? params.headerClass : '',
        // 20220901 이상훈 : 딤 영역 hide 옵션 params 에 추가
        hideOverlay: params.hideOverlay
      }
      return new Promise(function (resolve) {
        const prevElement = document.activeElement
        param.activeBtnBack = params.activeBtnBack !== undefined ? params.activeBtnBack : false
        console.log('[modal] plugin params', params)
        if (!isEmpty(params.modalName)) {
          bind.name = params.modalName
        } else {
          bind.name = 'common-modal-' + ranVal
        }
        // 20220901 이상훈 : 딤 영역 hide 옵션 params 에 추가
        if (isEmpty(params.hideOverlay)) {
          if (params.hideOverlay === undefined) {
            bind.hideOverlay = false
          } else {
            bind.hideOverlay = params.hideOverlay
          }
        }

        const modalParams = find(Vue.config.globalProperties.$modal.dynamicModals, function (item) {
          return item.bind.name === bind.name
        })

        if (!isEmpty(modalParams)) {
          Vue.config.globalProperties.$modal.hide(modalParams.name)
        }
        var defaultValue = {}
        if (typeof vueComponent === 'object') {
          defaultValue = {
            component: vueComponent,
            bind: {
              ...params
            }
          }
        } else {
          defaultValue = {
            component: UiModalContent,
            bind: {
              content: vueComponent
            }
          }
        }
        console.log('[bind]', bind)
        Vue.config.globalProperties.$modal.show({
          component: UiModal,
          bind: {
            ...bind
          },
          params: {
            ...param
          },
          on: {
            // event by v-modal
            confirm(close, result) {
              resolve(result)
              close()
            },
            cancel(close, result) {
              resolve(result)
              close()
            },
            // event by vue-final-modal
            // 'click-outside' () {
            //   console.log('@click-outside')
            // },
            // 'before-open' () {
            //   console.log('@before-open')
            // },
            // opened () {
            //   console.log('@opened')
            // },
            'before-close': function (event) {
              const rtnValue = pinia.popup.popupResult[event.ref.props.name]
              console.log('[modal] before-close', prevElement, event.ref.props.name, pinia.popup.popupResult[event.ref.props.name])
              resolve(rtnValue)
            },
            closed(event) {
              console.log('[modal] closed', event)
              pinia.popup.clearResult(event.ref.props.name)
              console.log('[modal] closed', event.ref.props.name, pinia.popup.popupResult[event.ref.props.name])
              console.log('@closed prevElement', prevElement, document.body.contains(prevElement), prevElement.nodeName.toLowerCase() !== 'body')
              if (prevElement.nodeName.toLowerCase() !== 'body' && document.body.contains(prevElement)) {
                prevElement.focus()
              }
            }
          },
          slots: {
            title: {
              component: UiModalHeader,
              bind: {
                text: title
              }
            },
            default: defaultValue
          }
        })
      })
    }
    // 모달이 닫힐 때 콜백을 닫힌 모달의 콜백을 전달한다
    Vue.modalHide = Vue.config.globalProperties.$modalHide = function (modalName, result = {}) {
      pinia.popup.closeResult({ modalName: modalName, result: result })
      Vue.config.globalProperties.$modal.hide(modalName)
    }
    // 전체 열려있는 모달을 모두 닫고 콜백을 초기화 한다
    Vue.closeModals = Vue.config.globalProperties.$closeModals = function () {
      pinia.popup.popupResult = []
      Vue.config.globalProperties.$modal.hideAll()
    }
  }
}

export default CommonModalPlugin
