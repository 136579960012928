<template class="dobu">
  <v-navigation-drawer v-model="activeModel" class="nav-main" location="right" temporary :width="windowWidth" @update:model-value="modelprint()">
    <div class="wrap-menu-top">
      <div class="header-menu-left">
        <button class="nav-top-title" @click="closeAsideClick()">
          <i class="icon-arrow-left"></i>
          <span class="menu-text">전체메뉴</span>
        </button>
      </div>
      <div class="header-menu-right">
        <!-- 로그인 프로필 이미지, 비로그인 아이콘 -->
        <button class="my-profile" title="프로필" @click="moveLogin()">
          <UiUserImg v-if="isAuth" :key="isAuth" :data="userData"></UiUserImg>
          <button v-else class="my-page"></button>
        </button>
      </div>
    </div>
    <div class="wrap-nav">
      <button class="item" @click="movePage('/')">
        <img src="/assets/main/nav-home.png" alt="" class="img-item" />
        <span class="item-text">홈</span>
      </button>
      <button class="item" @click="movePage('/CO/CO0100M01')">
        <img src="/assets/main/nav-community.png" alt="" class="img-item" />
        <span class="item-text">커뮤니티</span>
        <!--
          <div class="menu-tooltip">출시예정</div>
        -->
      </button>
      <button class="item" @click="movePage('/PR/PR0100M01')">
        <img src="/assets/main/nav-gift.png" alt="" class="img-item" />
        <span class="item-text">혜택</span>
      </button>
      <button class="item" @click="movePage('/PD/PD0101M01')">
        <img src="/assets/main/nav-lanking.png" alt="" class="img-item" />
        <span class="item-text">랭킹</span>
      </button>
      <!-- <button class="item">
        <img src="/assets/main/nav-card.png" alt="" class="img-item">
        <span class="item-text">카드</span>
      </button> -->
    </div>

    <div class="container top-line">
      <div class="wrap-side-nav-sub">
        <div class="nav-sub-tit">서비스 안내</div>
        <button class="item-sub" @click="movePage('/CS/CS0101M01')">공지사항</button>
        <button class="item-sub" @click="movePage('/CS/CS0103M01')">자주 묻는 질문</button>
        <button class="item-sub" @click="movePage('/CS/CS0103M03')">1:1문의</button>
        <!--
          <button class="item-sub" @click="logout()">로그아웃</button>
          <button class="item-sub" @click="movePage('/CS/CS0102M01')">이용약관</button>
        -->
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { isEmpty } from 'lodash'
import { UiUserImg } from '$$bizcomponents'
import DeviceUtil from '$$utils/device'
export default {
  name: 'SideMenu',
  components: { UiUserImg },
  data() {
    return {
      activeModel: false,
      isSnackbarOpen: false,
      snackbarText: '',
      snackbarType: ''
    }
  },
  computed: {
    windowWidth() {
      if (this.isDesktop) {
        return 428
      } else {
        return this.$novaCom.windowWidth()
      }
    },
    asideActive() {
      return this.$pinia.popup.asideActive
    },
    isAuth() {
      return this.$pinia.auth.isAuth
    },
    userData() {
      return this.$pinia.auth.userData
    },
    isDesktop() {
      return DeviceUtil.isDesktop()
    }
  },
  watch: {
    activeModel(val) {
      this.$pinia.popup.asideActive = val
    },
    asideActive() {
      this.activeModel = this.$pinia.popup.asideActive
    }
  },
  async mounted() {
    this.activeModel = this.asideActive
    console.log('this.activeModel', this.activeModel, this.asideActive)
  },
  methods: {
    closeAsideClick() {
      this.$pinia.popup.asideActive = !this.$pinia.popup.asideActive
    },
    moveLogin() {
      if (this.isAuth) {
        this.movePage('/MB/MB0200M01')
      } else {
        this.movePage('/CM/CM0100M01')
      }
    },
    movePage(pathStr, nameStr) {
      this.closeAsideClick()
      if (!isEmpty(pathStr)) {
        this.$router.push({ path: pathStr })
      } else if (isEmpty(nameStr)) {
        this.$router.push({ name: nameStr })
      }
    },
    modelprint() {
      console.log('this.activeModel', this.activeModel)
    }
    /*
    logout() {
      this.closeAsideClick()
      this.$pinia.auth.LOGOUT().then(() => {
        this.isSnackbarOpen = true
        this.snackbarType = 'logout'
        this.snackbarText = this.$t('message.STT_0007')
      })
    },
    closeSnackbar(openType) {
      this.isSnackbarOpen = false
      this.snackbarText = ''
      if (openType == 'logout') {
        this.$router.push({ name: 'CM0100M01' })
      }
    }
    */
  }
}
</script>

<style lang="scss">
@import '@/styles/settings/reset';
</style>
