<template>
  <v-app-bar class="header">
    <div class="header-menu-left">
      <button class="prev-btn" title="이전단계" @click="goBack()"></button>
      <p class="menu-tit">{{ menuName }}</p>
    </div>

    <div class="header-menu-right">
      <!-- 마이페이지 설정 -->
      <button class="setting-btn" title="설정" @click="movePage('/MB/MB0201M01')"></button>
      <button class="btn-menu" @click="activeAsideClick()">메뉴</button>
    </div>
  </v-app-bar>
</template>
<script>
import { isEmpty } from 'lodash'
export default {
  name: 'SettingHeader',
  components: {},
  data() {
    return {
      tabs: 'one'
    }
  },
  computed: {
    isMobile() {
      return this.$pinia.popup.mode === 'mobile'
    },
    headerName() {
      if (this.isMobile) {
        return '두부분식'
      } else {
        return '세상에 이런 폰트가 나오다니 천재인듯'
      }
    },
    menuName() {
      let pageInfo = this.$router.currentRoute.value.meta
      return pageInfo.title
    }
  },
  mounted() {},
  methods: {
    activeAsideClick() {
      this.$pinia.popup.asideActive = !this.$pinia.popup.asideActive
    },
    movePage(path, name) {
      console.log('[pageMove]', path)
      if (!isEmpty(path)) {
        this.$router.push({ path: path })
      } else {
        this.$router.push({ name: name, query: {}, state: {} })
      }
    },
    goBack() {
      let history = ''
      try {
        history = this.$router.options.history.state.back
      } catch (error) {
        this.movePage('/')
      }
      if (isEmpty(history)) {
        this.movePage('/')
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/settings/reset';
</style>
