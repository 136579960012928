// import axios from 'axios'
// import { each } from 'lodash'
// import * as yup from 'yup'
// 공통코드와 공통 validation메세지를 선언합니다
const commonCodePlugin = {
  install: function async() {
    // Vue, router, pinia
    // // console.log('[init commonCodePlugin!!!]', Vue, router, pinia)
    // const codeUrl = '/GW/router/MANAGEMENT/datacommmon/commonCode/listAll'
    // const params = {}
    // let commonCodeLoaded = false
    // // const tableCodeLoaded = false
    // let yupDefauldLoaded = false
    // router.beforeEach(async function (to, from, next) {
    //   if (!pinia.auth.isAuth) next()
    //   if (!yupDefauldLoaded) {
    //     // yuo 디폴트 메세지 setting
    //     yup.setLocale({
    //       mixed: {
    //         default: '사용할 수 없는 값입니다.',
    //         required: '필수 입력입니다.',
    //         // eslint-disable-next-line no-template-curly-in-string
    //         oneOf: '다음 값 중 하나여야 합니다.: ${values}',
    //         // eslint-disable-next-line no-template-curly-in-string
    //         notOneOf: '다음 값 중 하나가 아니어야 합니다.: ${values}',
    //         notType: function notType(_ref) {
    //           var path = _ref.path
    //           var type = _ref.type
    //           // var value = _ref.value
    //           // var originalValue = _ref.originalValue
    //           // var isCast = originalValue !== null && originalValue !== value
    //           if (type === 'number') {
    //             return ' 숫자만 입력해주세요.'
    //           } else if (type === 'date') {
    //             return ' 날짜 형식으로 입력해주세요.'
    //           } else {
    //             return path + ' 항목은 `' + type + '` 형식으로 입력해주세요.'
    //           }
    //         },
    //         defined: '정의되지 않았습니다.'
    //       },
    //       string: {
    //         // eslint-disable-next-line no-template-curly-in-string
    //         length: '${length}자로 입력해주세요.',
    //         // eslint-disable-next-line no-template-curly-in-string
    //         min: '${min}자 이상 입력바랍니다.',
    //         // eslint-disable-next-line no-template-curly-in-string
    //         max: '${max}자 까지 입력됩니다.',
    //         email: '이메일 형식이 아닙니다.'
    //       }
    //     })
    //     yupDefauldLoaded = true
    //   }
    //   if (to.path.indexOf('/error') === 0) {
    //     // pinia.menu.menuMaxCnt = 100
    //     next()
    //   }
    //   // 공통코드
    //   if (!commonCodeLoaded) {
    //     await axios
    //       .post(codeUrl, params, { loadingOff: false })
    //       .then(result => {
    //         const res = result.data
    //         if (res.result.code === 'SUCCESS') {
    //           const codeObj = []
    //           each(res.data.list, function (item) {
    //             const itemObj = {
    //               cmnCdTypeCd: item.cmnCdTypeCd,
    //               note: item.note,
    //               text: item.cmnCdVal,
    //               value: item.cmnCd
    //             }
    //             codeObj.push(itemObj)
    //           })
    //           pinia.auth.CODE_DATA_UPDATE(codeObj)
    //           commonCodeLoaded = true
    //         } else {
    //           console.log('[comonCode] error', res.result)
    //         }
    //       })
    //       .catch(err => {
    //         console.log('[comonCode] error', err)
    //       })
    //   }
    //   next()
    // })
  }
}

export default commonCodePlugin
