<template>
  <v-footer class="footer">
    <div class="aside-area">
      <img src="/assets/main/logo_footer.svg" alt="logo:두부분식" class="logo-footer" />
      <span class="corp-text" @click="movePage('/CS/CS0102M01')">이용약관</span>
      <span class="corp-text bold" @click="movePage('/CS/CS0107M01')">개인정보처리방침</span>
      <span class="corp-text" @click="movePage('/CS/CS0103M01')">고객센터</span>
    </div>
    <div class="copy-right">Copyright &copy; 하나증권 두부분식 All Right Reserved.</div>
    <div class="copy-right">회사명: 하나증권(주) | 사업지번호: 116-81-05992</div>
    <div class="copy-right">
      두부분식에서 제공하는 투자 정보는 고객의 투자 판단을 위한 단순 참고용일 뿐, 투자 제안 및 권유 종목 추천을 위해 작성된 것이 아닙니다.
    </div>
    <!-- <div class="copy-right">Copyright &copy; 두부분식 All Right Reserved.</div> -->
    <div class="sns-area">
      <img src="/assets/main/logo_telegram.svg" alt="텔레그램" class="footer-sns-logo" @click="openSns(teleUrl)" />
      <!-- TODO 인스타그램 오픈 시 진행
        <img src="/assets/main/logo_instagram.svg" alt="인스타그램" class="footer-sns-logo" />
      -->
    </div>

    <!-- <div class="text-center w-100">{{ new Date().getFullYear() }} — <strong>FNF Platform</strong></div> -->
  </v-footer>
</template>

<script>
import { isEmpty } from 'lodash'

export default {
  name: 'FooterPage',
  components: {},
  data() {
    return {
      teleUrl: 'https://t.me/do0bo0mental'
    }
  },
  computed: {},
  methods: {
    movePage(path, name) {
      if (!isEmpty(path)) {
        this.$router.push({ path: path })
      } else {
        this.$router.push({ name: name, query: {}, state: {} })
      }
    },
    openSns(snsUrl) {
      window.open(snsUrl, '_blank')
    }
  }
}
</script>
<style lang="scss">
@import '@/styles/settings/reset';
</style>
