import DeviceUtil from '$$utils/device'

const DevicePlugin = {
  install: function (Vue) {
    DeviceUtil.detect()
    DeviceUtil.detectBrowser()
    DeviceUtil.detectOs()
    DeviceUtil.detectChannel()

    Vue.$nativeApi.getAppVersion().then(function (result) {
      console.log('getAppVersion', result)
      DeviceUtil.setNativeVersion(result)
    })

    Vue.$device = Vue.config.globalProperties.$device = DeviceUtil
    console.log('DeviceUtil', DeviceUtil)
  }
}

export default DevicePlugin
